"use client";

/* eslint-disable jsx-a11y/alt-text */
import React from "react";

import { Box, Flex, Space, Stack, Text } from '@mantine/core';

import Image from "react-image-enlarger";
import FallbackBox from "../FallbackBox/FallbackBox";

interface ParticipantImageMessageBubbleProps {
    sentAt: Date;
    blobURL: string;
}

export default function ParticipantImageMessageBubble({ sentAt, blobURL }: ParticipantImageMessageBubbleProps): Readonly<React.ReactElement<ParticipantImageMessageBubbleProps> | null> {
    const [zoomed, setZoomed] = React.useState<boolean>(false);
    const [error, setError] = React.useState<React.SyntheticEvent<HTMLImageElement, Event> | null>(null);

    React.useEffect(() => setError(null), []);

    return (
        <Flex
            w={'70%'}
            h={'70%'} mt={'md'}
            direction={'row'}
            justify={'start'}
            align={'start'}
            wrap={'nowrap'}
        >
            <Flex
                w={'70%'}
                h={'70%'}
                direction={'row'}
                justify={'start'}
                align={'end'}
                wrap={'nowrap'}

            >
                <Stack gap={0}>
                    <Box
                        w={'100%'} h={'100%'}
                        style={{
                            borderTopRightRadius: 10,
                            borderBottomRightRadius: 10,
                            borderBottomLeftRadius: 10,
                            borderRadius: "12px"
                        }}
                        maw={620}
                    >
                        {error ? (
                            <FallbackBox message="ไม่สามารถดึงข้อมูลรูปภาพได้" />
                        ) : (
                            <Image
                                zoomed={zoomed}
                                src={blobURL}
                                onClick={() => setZoomed(true)}
                                onRequestClose={() => setZoomed(false)}
                                onError={setError}
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'cover',
                                    overflow: 'hidden',
                                    borderRadius: "12px"
                                }}
                            />
                        )}
                    </Box>
                </Stack>
                <Space ml={12} />
                <Text c={'#404040'}>{sentAt.toLocaleTimeString()}</Text>
            </Flex>
        </Flex>
    );
}
