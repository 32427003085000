import React from "react";

import { Box, Flex, Stack, Text } from "@mantine/core";

interface AdminTextMessageBubbleProps {
    sentAt: Date;

    messageText?: string | undefined;
    senderName?: string | undefined;
}

export default function AdminTextMessageBubble({ sentAt, messageText, senderName }: AdminTextMessageBubbleProps): Readonly<React.ReactElement<AdminTextMessageBubbleProps>> {
    return (
        <Flex
            w={'100%'} mt={'md'}
            h={'auto'}
            direction={'row'}
            justify={'end'}
            align={'end'}
            wrap={'nowrap'}
        >
            <Flex
                w={'100%'}
                h={'auto'}
                direction={'row'}
                justify={'end'}
                align={'end'}
                wrap={'nowrap'}
            >
                <Stack c={'#404040'} gap={0} align={'end'}>
                    <Text>({senderName})</Text>
                    <Text>{sentAt.toLocaleTimeString()}</Text>
                </Stack>
                <Box
                    ml={16}
                    w={'auto'}
                    h={'auto'}
                    p={12}
                    bg={'#f2b105'}
                    maw={"50%"}
                    style={{
                        borderTopLeftRadius: 16,
                        borderTopRightRadius: 16,
                        borderBottomLeftRadius: 16,
                    }}
                >
                    <Text fz={20} c={'dark'}>
                        {messageText}
                    </Text>
                </Box>
            </Flex>
        </Flex>
    );
}
