"use client";

import React from "react";

import { Text } from "@mantine/core";
import { BotStatuses } from "@vorderseite/types/bot";

interface EnabledTextProps {
    statuses?: BotStatuses;
    fontSize?: string | number | undefined
}

export default function EnabledText({ statuses, fontSize = 18 }: EnabledTextProps): Readonly<React.ReactElement<EnabledTextProps>> {
    if (statuses === "enabled") {
        return <Text fz={fontSize} fw={700} c={'#54d815'}>กำลังใช้งาน</Text>;
    }

    return <Text fz={fontSize} fw={700} c={'#c42c23'}>ปิดการใช้งาน</Text>;
}
