import React from "react";

import Image from "next/image";

import { useStore } from "@nanostores/react";
import { $selectedRoom } from "@vorderseite/store/rooms";

import { CounterBubble } from "..";

import { Box, Flex, Group, MantineSpacing, StyleProp, Text } from "@mantine/core";

import { Room } from "@vorderseite/types/room";
import { getTimePassed } from "@vorderseite/utils/time";

import ParticipantImage from "./../../../public/partcipant.png";

interface ParticipantProfileShowcaseProps {
    width?: number | string;
    px?: StyleProp<MantineSpacing> | undefined
    imageWidth?: number | string;
    imageHeight?: number | string;
    hovered?: boolean | undefined;
    hasMessages?: boolean | undefined;

    room: Room;
}

export default function ParticipantProfileShowcase({ width = '100%', px = 8, imageWidth = 64, imageHeight = 64, hovered = true, hasMessages = true, room }: ParticipantProfileShowcaseProps): Readonly<React.ReactElement<ParticipantProfileShowcaseProps>> {
    const selectedRoom = useStore($selectedRoom);
    const isRoomIncluded = selectedRoom?.room_id === room.room_id;

    const [isHovering, setIsHovering] = React.useState<boolean>(false);

    const latestOfReply = React.useMemo(() => {
        const startDate = new Date(room.latest_reply_at);
        const timePassed = getTimePassed(startDate, new Date());

        if (timePassed.days === 0 && timePassed.hours === 0 && timePassed.minutes === 0) {
            return "(เมื่อไม่นานมานี้)"
        }

        let message: string = "(เมื่อ "
        if (timePassed.days > 0) {
            message += `${timePassed.days} วัน `;
        } else if (timePassed.days > 9) {
            return "(เมื่อ 10 วันที่แล้ว)"
        }

        if (timePassed.hours > 0) {
            message += `${timePassed.hours} ชั่วโมง `;
        }

        if (timePassed.minutes > 0) {
            message += `${timePassed.minutes} นาที`
        }

        message += "ที่แล้ว)"

        return message;
    }, [room.latest_reply_at]);

    return (
        <Flex
            w={width}
            h={'auto'}
            px={px}
            py={12}
            direction={'row'}
            justify={'center'}
            align={'center'}
            wrap={'nowrap'}
            bg={
                isRoomIncluded && hovered ?
                    '#ffefc5' : !isRoomIncluded && isHovering && hovered
                        ? '#f7f7f7' : ''
            }
            style={{
                transitionProperty: 'all',
                transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)',
                transitionDuration: '150ms',
                borderRadius: '12px',
                cursor: "pointer"
            }}
            onMouseEnter={() => void setIsHovering(true)}
            onMouseLeave={() => void setIsHovering(false)}
        >
            <Group gap={'xs'} w={'100%'} wrap="nowrap">
                <Box
                    w={imageWidth}
                    h={imageHeight}
                    pos={'relative'}
                    style={{
                        borderRadius: '50%',
                        overflow: 'hidden'
                    }}
                    bg={'#ececec'}

                >
                    <Image
                        src={room.participant.picture_url ? room.participant.picture_url : ParticipantImage}
                        alt={`${room.participant.display_name}'s picture`}
                        fill
                        sizes="auto"
                        style={{
                            width: '100%',
                            height: '100%',
                            position: 'absolute',
                            objectFit: 'cover',
                            overflow: 'hidden'
                        }}
                    />
                </Box>
                <Flex
                    direction={'column'}
                    justify={'center'}
                    align={'flex-start'}
                    wrap={'nowrap'}
                    gap={0}
                >
                    <Text w={'auto'} fz={20} fw={600} c={'#3a3a3a'}>{room.participant.display_name}</Text>
                    <Text w={'auto'} fz={16 } c={'#7f7f7f'}>{room.bot.display_name}</Text>
                    <Text c={'#7f7f7f'}>{latestOfReply}</Text>
                </Flex>
            </Group>
            {hasMessages &&
                <CounterBubble
                    totalMessages={room.total_unseen_messages}
                />
            }
        </Flex>
    );
}
