import React from "react";

import { Box, Flex, Space, Stack, Text } from "@mantine/core";

interface ParticipantTextMessageBubbleProps {
    sentAt: Date;
    message_text?: string | undefined;
}

export default function ParticipantTextMessageBubble({ sentAt, message_text }: ParticipantTextMessageBubbleProps): Readonly<React.ReactElement<ParticipantTextMessageBubbleProps>> {
    return (
        <Flex
            w={'100%'}
            h={'auto'} mt={'md'}
            direction={'row'}
            justify={'start'}
            align={'start'}
            wrap={'nowrap'}
            style={{ borderRadius: '12px' }}
        >
            <Flex
                w={'100%'}
                h={'auto'}
                direction={'row'}
                justify={'start'}
                align={'end'}
                wrap={'nowrap'}
                style={{ borderRadius: '12px' }}
            >
                <Stack gap={0}>
                    <Box
                        w={'auto'} h={'auto'}
                        p={10} bg={'#ededed'}
                        style={{
                            borderTopRightRadius: 10,
                            borderBottomRightRadius: 10,
                            borderBottomLeftRadius: 10,
                        }}
                    >
                        <Text fz={20}>{message_text}</Text>
                    </Box>
                </Stack>
                <Space ml={12} />
                <Text c={'#404040'}>{sentAt.toLocaleTimeString()}</Text>
            </Flex>
        </Flex>
    );
}
