"use client";

import React from "react";

import Link from "next/link";
import Image from "next/image";
import { usePathname } from "next/navigation";

import { Box, Flex, Group, Space, Stack, Text, Title, Tooltip, UnstyledButton } from "@mantine/core";

import { MagicBorder } from "..";

import { useStore } from "@nanostores/react";
import { $userProfile } from "@vorderseite/store/users";

import { notoSerif, seedSans } from "@vorderseite/styles/fonts";
import { restricted } from "@vorderseite/utils/restricted";
import type { Navigate } from "@vorderseite/types/navigate";

import { IconDots, IconInbox } from "@tabler/icons-react";

import PartcipantImage from "./../../../public/partcipant.png";
import { $tabSelectorState } from "@vorderseite/store/states";

interface NavigationBarProps {
    navigates: Navigate[];
    openUtilityModal?: () => void;
}

export default function NavigationBar(props: NavigationBarProps): Readonly<React.ReactElement<NavigationBarProps>> {
    const pathname = usePathname();

    const userProfile = useStore($userProfile);
    const TabSelectorState = useStore($tabSelectorState);

    return (
        <Flex
            w={'100%'} h={'6.5rem'}
            px={20} direction={'row'}
            justify={'space-between'}
            align={"center"}
            wrap={'nowrap'}
            style={{ borderBottom: '1px solid #ececec' }}
        >
            <Link href={{ pathname: '/chats' }}>
                <Title
                    className={notoSerif.className}
                    fz={52}
                >
                    LINE Chats&apos;s ATAuto
                </Title>
            </Link>
            <Flex
                h={'100%'}
                direction={'row'}
                justify={'center'}
                align={'center'}
            >
                <Group mx={'lg'}>
                    {/* TODO: More effiently for role protection. */}
                    {!restricted(pathname, userProfile.role) ? (
                        <>
                            {props.navigates.map((navigate) => {
                                return (
                                    <Tooltip key={navigate.id} label={`หรือใช้คำสั่ง ${navigate.shortcut} เพื่อเปลี่ยนหน้า`}>
                                        <Link href={navigate.pathname} onClick={() => $tabSelectorState.set(navigate.pathname)}>
                                            <Group
                                                gap={'xs'}
                                                c={TabSelectorState === navigate.pathname ? "gray.8" : "gray.6"}
                                            >
                                                {navigate.icon}
                                                <Text>{navigate.label}</Text>
                                            </Group>
                                        </Link>
                                    </Tooltip>
                                );
                            })}
                        </>
                    ) : (
                        <Tooltip label={`หรือใช้คำสั่ง Ctrl+Q เพื่อเปลี่ยนหน้า`}>
                            <Link href={"/chats"} onClick={() => $tabSelectorState.set("/chats")}>
                                <Group
                                    gap={'xs'}
                                    c={TabSelectorState === "/chats" ? "gray.8" : "gray.6"}
                                >
                                    <IconInbox size={26} color="#7c7c7c" />
                                    <Text>กล่องข้อความ</Text>
                                </Group>
                            </Link>
                        </Tooltip>
                    )}
                </Group>
                <Stack gap={0} align={'flex-end'} miw={100}>
                    <Text
                        className={seedSans.className}
                        fw={600}
                        fz={16}
                    >
                        บัญชีผู้ใช้งาน
                    </Text>
                    <Text
                        className={notoSerif.className}
                        fw={500}
                        fz={16}
                        c={'#7f7f7f'}
                    >
                        {userProfile.username}
                    </Text>
                </Stack>
                <Space mr={'lg'} />
                <Box
                        w={56} h={56}
                        pos={'relative'}
                        style={{
                            borderRadius: '50%',
                            overflow: 'hidden',
                        }}
                        bg={'#ececec'}
                     >
                        <Image
                            src={PartcipantImage}
                            alt="participant's image"
                            fill
                            sizes="auto"
                            style={{
                                width: '100%',
                                height: '100%',
                                position: 'absolute',
                                objectFit: 'cover',
                                overflow: 'hidden'
                            }}
                        />
                    </Box>
                <Space mr={'lg'} />
                <Flex
                    justify={'end'}
                    align={'center'}
                >
                    <MagicBorder
                        width={38}
                        height={38}
                        hover
                        onClick={props.openUtilityModal}
                    >
                        <IconDots size={26} color={'#7f7f7f'} />
                    </MagicBorder>
                </Flex>
            </Flex>
        </Flex>
    );
}
